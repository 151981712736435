<template>
    <div class="pagebox">
        <!-- 顶部 -->
        <PHeader></PHeader>

        <!-- 院校 -->
        <div class="college_box">
            <div class="college_con">
                <!-- 搜索院校、已选条件 -->
                <div class="searchbox">
                    <div class="i_box">
                        <div class="qbtn" @click.prevent="CollegeSearchBtn(1)">筛选条件<i class="el-icon-d-arrow-right"></i></div>
                        <div class="num">共找到 <span class="n">{{CollegeNumber}}</span> 所院校</div>
                        <div class="stxt">
                            <el-input id="College_Input_ID" placeholder="搜索院校" v-model="CollegeSeachForm.CollegeName" @keyup.enter.native="searchCollege" @focus="ShowCollegeSearch=true" clearable @clear="clearCollegeSearch"></el-input>
                            <div v-show="ShowCollegeSearch" id="College_Show_ID" class="search-box">
                                <div class="search-li" v-for="SCItem in SearchCollegeList" :key="SCItem.Id" @click="CollegeDetail(SCItem.Id)">
                                    <i v-show="SelectSearchList.find(i => i.Id === SCItem.Id)" class="selected-icon iconfont">&#xe68a;</i>
                                    {{ SCItem.Name }}
                                </div>
                                <div class="search-sure" v-if="SearchCollegeList.length > 0" @click.prevent="SureScreen">
                                    <span class="text">确 认</span>
                                </div>
                                <div class="no-data" v-if="SearchCollegeList.length <= 0">
                                    <span>暂无数据</span>
                                </div>
                            </div>
                        </div>
                        <div id="Search_Button_ID" class="s_btn" @click="searchCollege"><i class="el-icon-search"></i>搜 索</div>
                        <div class="clear"></div>
                    </div>
                    <div class="t_box" v-if="query.CollegeCityIdList.length!=0||query.CollegeCategoryIdList.length!=0||query.CollegeNatureIdList.length!=0||query.EducationLevel!=null||query.FeatureTagList.length!=0||query.StrengthTagList.length!=0||BottomCollegeCardList.length!=0||query.IsNewCollege">
                        <div class="ttxt">已选条件 ：</div>
                        <div class="t_data">
                            <!-- <div class="data" v-if="query.CollegeCityIdList.length!=0">
                                <span class="tit">院校地区：</span>
                                <span class="d" v-for="itemOne in CollegeCityCheckList" :key="itemOne.CityId">{{itemOne.CityName}}<i class="el-icon-circle-close" @click.prevent="ProvinceCityRemove(itemOne)"></i></span>
                                <div class="close" @click.prevent="ProvinceCityRemove('')"><i class="el-icon-close"></i></div>
                            </div> -->
                            
                            <div class="data" v-if="query.CollegeCityIdList.length!=0">
                                <span class="tit">院校地区：</span>
                                <span class="d" v-for="itemOne in CollegeCityCheckList" :key="itemOne.ProvinceId">{{itemOne.ProvinceName}}<i class="el-icon-circle-close" @click.prevent="ProvinceRemove(itemOne.ProvinceId)"></i></span>
                                <div class="close" @click.prevent="ProvinceRemove('')"><i class="el-icon-close"></i></div>
                            </div>
                            <div class="data" v-if="query.CollegeCategoryIdList.length!=0">
                                <span class="tit">院校类别：</span>
                                <span class="d" v-for="itemTwo in query.CollegeCategoryIdList" :key="itemTwo">{{showName(itemTwo, 2)}}<i class="el-icon-circle-close" @click.prevent="CollegeCategoryRemove(itemTwo)"></i></span>
                                <div class="close" @click.prevent="CollegeCategoryRemove('')"><i class="el-icon-close"></i></div>
                            </div>
                            <div class="data" v-if="query.CollegeNatureIdList.length!=0">
                                <span class="tit">办学性质：</span>
                                <span class="d" v-for="itemThree in query.CollegeNatureIdList" :key="itemThree">{{showName(itemThree,3)}}<i class="el-icon-circle-close" @click.prevent="CollegeNatureRemove(itemThree)"></i></span>
                                <div class="close" @click.prevent="CollegeNatureRemove('')"><i class="el-icon-close"></i></div>
                            </div>
                            <div class="data" v-if="(query.EducationLevel==0||query.EducationLevel==1)">
                                <span class="tit">学历层次：</span>
                                <span class="d">{{query.EducationLevel==0?'专科':'本科'}}<i class="el-icon-circle-close" @click.prevent="CollegeLevelRemove"></i></span>
                                <div class="close" @click.prevent="CollegeLevelRemove"><i class="el-icon-close"></i></div>
                            </div>
                            <div class="data" v-if="query.StrengthTagList.length!=0">
                                <span class="tit">实力标签：</span>
                                <span class="d" v-for="itemSix in query.StrengthTagList" :key="itemSix">{{showName(itemSix,6)}}<i class="el-icon-circle-close" @click.prevent="CollegeStrengthTagRemove(itemSix)"></i></span>
                                <div class="close" @click.prevent="CollegeStrengthTagRemove('')"><i class="el-icon-close"></i></div>
                            </div>
                            <div class="data" v-if="query.FeatureTagList.length!=0">
                                <span class="tit">特色标签：</span>
                                <span class="d" v-for="itemFive in query.FeatureTagList" :key="itemFive">{{showName(itemFive,5)}}<i class="el-icon-circle-close" @click.prevent="CollegeFeatureTagRemove(itemFive)"></i></span>
                                <div class="close" @click.prevent="CollegeFeatureTagRemove('')"><i class="el-icon-close"></i></div>
                            </div>
                            <!-- <div class="data" v-if="query.IsNewCollege">
                                <span class="tit">其他选项：</span>
                                <span class="d">新增院校<i class="el-icon-circle-close" @click.prevent="IsNewCollegeRemove()"></i></span>
                                <div class="close" @click.prevent="IsNewCollegeRemove()"><i class="el-icon-close"></i></div>
                            </div> -->
                            <div class="data" v-if="BottomCollegeCardList.length!=0">
                                <span class="tit">院校：</span>
                                <span class="d" v-for="(SItem,SIndex) in BottomCollegeCardList" :key="String(SItem.CollegeId)+SIndex">{{SItem.CollegeName}}<i class="el-icon-circle-close" @click.prevent="deleteSSList(SItem,SIndex)"></i></span>
                                <div class="close" @click.prevent="deleteSSListAll('')"><i class="el-icon-close"></i></div>
                            </div>
                            <div class="clear"></div>
                        </div>
                        <div class="clear"></div>
                    </div>
                </div>
                <!-- 筛选弹层 -->
                <div class="query_layer_box" id="query_layer_box" @click.prevent="CloseSearchLayerOfBack()">
                    <div class="m_box" @click.prevent.stop="">
                        <div class="m_list" id="college_list_box">
                            <el-scrollbar wrap-class="scrollbar-wrapper" class="sx_scrollbar">
                                <!-- <div class="s_box">
                                    <div class="tit">院校地区：</div>
                                    <div class="box">
                                        <el-popover v-for="province in CollegeScreen.CollegeAreaList" :key="province.ProvinceId" trigger="hover" placement="bottom" width="450" popper-class="search_city_popover">
                                            <div class="search_citybox">
                                                <div class="citem" :class="province.CheckNumber==province.CityList.length?'on':''" @click.prevent="ProvinceCityAllCheck(province)">全部</div>
                                                <div class="citem" :class="queryCopy.CollegeCityIdList.find(f=>f==item1.CityId)?'on':''" v-for="item1 in province.CityList" :key="item1.CityId" @click.prevent="ProvinceCityCheck(item1)">{{item1.CityName}}</div>
                                            </div>
                                            <el-button slot="reference">{{province.ProvinceName}}<i class="el-icon-arrow-down"></i><span class="num" v-if="province.CheckNumber">{{province.CheckNumber}}</span></el-button>
                                        </el-popover>
                                    </div>
                                    <div class="clear"></div>
                                </div> -->
                                <div class="s_box">
                                    <div class="tit">院校地区：</div>
                                    <div class="box">
                                        <span class="item" :class="(queryCopy.CollegeCityIdList.length==0?'on':'')" @click.prevent="ProvinceCheck('')">不限</span>
                                        <span class="item" :class="(queryCopy.CollegeCityIdList.find(f=>f==item1.ProvinceId)?'on':'')" v-for="item1 in CollegeScreen.CollegeAreaList" :key="item1.ProvinceId" @click.prevent="ProvinceCheck(item1)">{{item1.ProvinceName}}</span>
                                    </div>
                                    <div class="clear"></div>
                                </div>
                                <div class="s_box">
                                    <div class="tit">院校类别：</div>
                                    <div class="box">
                                        <span class="item" :class="(queryCopy.CollegeCategoryIdList.length==0?'on':'')" @click.prevent="CollegeCategoryCheck('')">不限</span>
                                        <span class="item" :class="(queryCopy.CollegeCategoryIdList.find(f=>f==item2.Id)?'on':'')" v-for="item2 in CollegeScreen.CollegeCategoryList" :key="item2.Id" @click.prevent="CollegeCategoryCheck(item2)">{{item2.Name}}</span>
                                    </div>
                                    <div class="clear"></div>
                                </div>
                                <div class="s_box level">
                                    <div class="tit">办学性质：</div>
                                    <div class="box">
                                        <span class="item" :class="(queryCopy.CollegeNatureIdList.length==0?'on':'')" @click.prevent="CollegeNatureCheck('')">不限</span>
                                        <span class="item" :class="(queryCopy.CollegeNatureIdList.find(f=>f==item3.Id)?'on':'')" v-for="item3 in CollegeScreen.CollegeNatureList" :key="item3.Id" @click.prevent="CollegeNatureCheck(item3)">{{item3.Name}}</span>
                                        <span v-if="ProvinceObj.EnableRecommendEducationLevel" class="level">
                                            <span class="level-title">学历层次：</span>
                                            <span class="level-box">
                                                <span class="level-item" :class="queryCopy.EducationLevel==1?'on':''" @click="CollegeLevelCheck(1)">本科</span>
                                                <span class="level-item" :class="queryCopy.EducationLevel==0?'on':''" @click="CollegeLevelCheck(0)">专科</span>
                                            </span>
                                        </span>
                                    </div>
                                    <div class="clear"></div>
                                </div>
                                <div class="s_box">
                                    <div class="tit">实力标签：</div>
                                    <div class="box">
                                        <span class="item" :class="(queryCopy.StrengthTagList.length==0?'on':'')" @click.prevent="CollegeStrengthTagCheck('')">不限</span>
                                        <span class="item" :class="(queryCopy.StrengthTagList.find(f=>f==item6.Id)?'on':'')" v-for="item6 in CollegeScreen.StrengthTagList" :key="item6.Id" @click.prevent="CollegeStrengthTagCheck(item6)">{{item6.Name}}</span>
                                    </div>
                                    <div class="clear"></div>
                                </div>
                                <div class="s_box">
                                    <div class="tit">特色标签：</div>
                                    <div class="box">
                                        <span class="item" :class="(queryCopy.FeatureTagList.length==0?'on':'')" @click.prevent="CollegeFeatureTagCheck('')">不限</span>
                                        <span class="item" :class="(queryCopy.FeatureTagList.find(f=>f==item5.Id)?'on':'')" v-for="item5 in CollegeScreen.FeatureTagList" :key="item5.Id" @click.prevent="CollegeFeatureTagCheck(item5)">{{item5.Name}}</span>
                                    </div>
                                    <div class="clear"></div>
                                </div>
                                <!-- <div class="s_box">
                                    <div class="tit">其他选项：</div>
                                    <div class="box">
                                        <span class="item" :class="(queryCopy.IsNewCollege!=null?'on':'')" @click.prevent="IsNewCollegeCheck()">新增院校</span>
                                    </div>
                                    <div class="clear"></div>
                                </div> -->
                                <div class="clear" style="height:80px"></div>
                                <div class="btnbox">
                                    <div class="s_back" @click.prevent="CloseSearchLayerOfBack()"><i class="el-icon-arrow-left" style="color:#999"></i> 返 回</div>
                                    <div class="s_submit" @click.prevent="SeachFormSubmit"><i class="el-icon-search"></i> 查 询</div>
                                </div>
                            </el-scrollbar>
                        </div>
                    </div>
                </div>
                <!-- 列表 -->
                <div class="college_list">
                    <div class="college_table">
                        <div class="college_item" v-for="item in CollegeData" :key="item.Id">
                            <div class="logo" @click.prevent="CollegeDetail(item.Id)" :style="{'background':'url('+item.CollegeLogoFileUrl+') no-repeat center center / cover'}"></div>
                            <div class="mark">
                                <div>
                                    <span class="name" @click.prevent="CollegeDetail(item.Id)">{{item.CollegeName}}</span>
                                    <div class="con">
                                        <span class="txt"><i class="iconfont">&#xe615;</i>{{item.ProvinceName}}<span v-if="item.ProvinceName&&item.CityName"> · </span>{{item.CityName}}</span>
                                        <span class="txt"><i class="iconfont">&#xe679;</i>{{item.ChargeDept}}<span v-if="item.ChargeDept&&item.NatureName"> · </span>{{item.NatureName}}<span v-if="(item.ChargeDept||item.NatureName)&&item.CategoryName"> · </span>{{item.CategoryName}}</span>
                                        <span class="txt"><i class="iconfont">&#xe661;</i>{{item.EducationLevelStr}}</span>
                                        <span class="txt"></span>
                                    </div>
                                </div>
                                <div class="dress" v-if="item.Address||item.Tel">
                                    <span v-if="item.Address"><i class="el-icon-location-outline"></i>{{item.Address}}</span>
                                    <span v-if="item.Tel"><i class="el-icon-phone-outline"></i>{{item.Tel}}</span>
                                </div>
                                <div class="tips" :id="'college_tip_id'+item.Id" v-if="item.StrengthTags||item.FeatureTags">
                                    <span v-for="t1 in GetTagsArr(item.StrengthTags)" :key="(t1+'')">{{t1}}</span>
                                    <span v-for="t2 in GetTagsArr(item.FeatureTags)" :key="(t2+'')">{{t2}}</span>
                                    <div class="more" :id="'college_tip_down'+item.Id" @click.prevent="CollegeTipBtn(item.Id,1)">展开 <i class="el-icon-arrow-down"></i></div>
                                    <div class="more" :id="'college_tip_up'+item.Id" @click.prevent="CollegeTipBtn(item.Id,2)" style="display:none;">收起 <i class="el-icon-arrow-up"></i></div>
                                </div>
                            </div>
                            <div class="btnbox">
                                <div class="btn" :class="CollegeCompareData.find(f=>f.Id==item.Id)?'remove':'add'" @click.prevent="CollegeAddCompare(item,(CollegeCompareData.find(f=>f.Id==item.Id)?2:1))">{{CollegeCompareData.find(f=>f.Id==item.Id)?'移除对比':'添加对比'}}<i class="el-icon-minus" v-if="CollegeCompareData.find(f=>f.Id==item.Id)"></i><i class="el-icon-plus" v-else></i></div>
                            </div>
                            <div class="clear"></div>
                        </div>
                        <div class="pages" @click.prevent="pagesbtn()">
                            <i class="iconfont" v-if="CollegeData.length==0&&pageIndex==1">&#xe67c;</i>
                            <span>{{pagetxt}}</span>
                        </div>
                    </div>
                    <div class="ad_con">
                        <!-- 填报 -->
                        <div class="zntb_box" @click.prevent="ToZntb()">
                            <div class="img">
                                <img src="../../../assets/images/ad-zhinengtuijian.png" alt="" title="点击进入智能填报"/>
                            </div>
                        </div>
                        <!-- 热门院校 -->
                        <div class="rmyx_box">
                            <div class="rtit"><i></i><span>热门院校</span><div class="clear"></div></div>
                            <div class="rlist">
                                <div class="ra" v-for="(item,index) in HotCollegeData" :key="index" @click.prevent="CollegeDetail(item.Id)">
                                    <div class="ico"><i class="iconfont" v-if="index<3">&#xe64e;</i><span>{{index+1}}</span></div>
                                    <div class="txt nowrap">{{ item.CollegeName }}</div>
                                </div>
                            </div>
                        </div>
                        <!-- 院校排行榜 -->
                        <div class="college_order" @click.prevent="toCollegeOrder()">
                            <i class="iconfont">&#xe66d;</i>
                            <div class="name">院校排行榜</div>
                        </div>
                    </div>
                    <div class="clear"></div>
                </div>
                <!-- 对比 -->
                <div class="compare_box" :style="{'right':CompareFixed+'px'}" :class="AnimateCompareClass">
                    <div class="compare">
                        <div class="open" @click.prevent="OpenCompareBtn">
                            <div class="ico">
                                <i class="el-icon-d-arrow-right" v-if="CompareFixed==0"></i>
                                <i class="el-icon-d-arrow-left" v-else></i>
                            </div>
                            <div class="tit">{{CompareFixed==0?'收起院校对比':'展开院校对比'}}
                                <div class="num" v-if="CollegeCompareData.length>0">{{CollegeCompareData.length}}</div>
                            </div>
                            <div class="clear"></div>
                        </div>
                        <div class="db" v-if="CollegeCompareData.length>0" @click.prevent="StartCompareBtn"><span>开始对比</span></div>
                        <div class="list">
                            <div class="search">
                                <i class="el-icon-search"></i>
                                <el-select v-model="CompareForm.Id" clearable filterable remote reserve-keyword placeholder="请输入院校关键字" @change="CompareCollegeChange" :remote-method="RemoteMethodCollege" :loading="cloading">
                                    <el-option v-for="item in CollegeSelectData" :key="item.Id" :label="item.Name" :value="item.Id" :disabled="item.Disabled" :title="(item.Disabled?'已添加：'+item.Name:'')"></el-option>
                                </el-select>
                            </div>
                            <div class="dlist">
                                <el-scrollbar wrap-class="scrollbar-wrapper" class="comparescroll">
                                    <div class="dl" :class="(CollegeCompareCheckData.find(f=>f===item.Id)?'on':'')" v-for="(item,index) in CollegeCompareData" :key="index">
                                        <span class="check" @click.prevent="CollegeCheck(item)"><span class="ch"><i class="el-icon-check"></i></span></span>
                                        <div class="sname" @click.prevent="CollegeCheck(item)">
                                            <el-tooltip effect="light" :disabled="(item.CollegeName.length>11?false:true)" :content="item.CollegeName" placement="left">
                                                <el-button>{{item.CollegeName}}</el-button>
                                            </el-tooltip>
                                        </div>
                                        <i class="delete el-icon-delete" @click.prevent="CollegeAddCompare(item,2)"></i>
                                    </div>
                                    <div v-if="CollegeCompareData.length==0" style="text-align:center;color:#888;font-size:13px;line-height:60px;">请选择要对比的院校</div>
                                </el-scrollbar>
                                <div class="mark">每次最多可选5条记录对比</div>
                                <div class="btn" @click.prevent="StartCompareBtn">开始对比</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 底部 -->
        <PFooter></PFooter>
    </div>
</template>
<script>
import PHeader from "@/components/Header.vue";
import PFooter from "@/components/Footer.vue";
import API from "@/api/config";
import { getStore, setStore, removeStore } from "@/utils/utils";
import { deepClone } from '@/utils/index'
export default {
    components:{
        PHeader,
        PFooter
    },
    data(){
        return {
            loading:false,
            pageIndex:1,
            pageSize:10,
            pagetxt:'未查询到院校',
            HasNext:false,
            CollegeNumber:2923,
            CollegeData:[],//列表
            HotCollegeData:[],
            CompareFixed:-240,
            AnimateCompareClass:'',

            ProvinceObj: {}, // 省份
            SelectWishWayObj: {},
            CollegeScreen: {},
            MajorScreenSelectLevel: 0,
            CollegeCityCheckList:[],
            CollegeCityCheckListCopy:[],
            CollegeSeachForm:{
                CollegeName:''
            },
            ShowCollegeSearch: false, // 搜索面板
            SearchCollegeList: [],
            SelectSearchList: [], // 被选中的，搜索出来的院校
            BottomCollegeCardList: [],
            query:{
                CollegeCityIdList: [],// 院校地区
                CollegeCategoryIdList: [],// 院校类别
                CollegeNatureIdList: [],// 办学性质
                EducationLevel: null, // 学历层次
                StrengthTagList: [],// 实力标签
                FeatureTagList: [], // 特色标签
                CollegeIdList: [],// 院校
				IsNewCollege: null,
            },
            queryCopy:{
                CollegeCityIdList: [],// 院校地区
                CollegeCategoryIdList: [],// 院校类别
                CollegeNatureIdList: [],// 办学性质
                EducationLevel: null, // 学历层次
                StrengthTagList: [],// 实力标签
                FeatureTagList: [], // 特色标签
                CollegeIdList: [],// 院校
				IsNewCollege: null,
            },
            searchForm:{
                ProvinceIdList:[],
                CategoryIdList:[],
                NatureIdList:[],
                EducationLevel:null,
                StrengthTagIdList:[],
                FeatureTagIdList:[],
                CollegeName:''
            },
            CompareForm:{
                Id:null
            },
            CollegeSelectData:[],
            cloading:false,
            CollegeCompareData:[],
            CollegeCompareCheckData:[],
            
        }
    },
    mounted(){
        this.initial();
    },
    methods:{
        initial(){
            setStore("QueryCollegeStoreData",'');//清除筛选数据
            this.getInfo()
            // 收起搜索面板
            setTimeout(() => {
                let Ele1 = document.getElementById('College_Input_ID')
                let Ele2 = document.getElementById('College_Show_ID')
                let Ele3 = document.getElementById('Search_Button_ID')
                const ClickHidden = (event) => {
                    
                    if (event.target !== Ele1 && event.target !== Ele2 && event.target !== Ele3 && !Ele2.contains(event.target)) {
                        this.ShowCollegeSearch = false
                    }
                }
                document.removeEventListener('click', ClickHidden)
                document.addEventListener('click', ClickHidden)
            }, 0)
        },
        getInfo(){
            // 获取选中省份
            let province = getStore('OrgProvince')
            if (!province) {
                this.$message.warning('未获取到省份数据！')
                return false
            }
            this.ProvinceObj = JSON.parse(province)

            this.ResetOrClearSearchStoreData();// 缓存、重置 筛选信息
            this.getScreen()// 获取筛选数据
            this.GetCollegeList();//获取院校列表
            this.GetHotCollegeList();
            //获取对比数据
            let cc1 = getStore("CollegeCompareItem");
            if(cc1){
                this.CollegeCompareData=JSON.parse(cc1);
            }
            let cc2 = getStore("CollegeCompareCheckItem",this.CollegeCompareCheckData);
            if(cc2){
                this.CollegeCompareCheckData=JSON.parse(cc2);
            }
        },
        // 获取筛选数据
        getScreen() {
            // 院校优先
            API.Web.GetCollegeQueryParameter().then(res => {
                this.CollegeScreen = res.Data;
                // 院校地区参数处理
                this.CollegeScreen.CollegeAreaList = this.CollegeScreen.CollegeAreaList||[];
            });

            if(this.SelectWishWayObj.EducationLevel!==0 && this.SelectWishWayObj.EducationLevel!==1) {
                this.MajorScreenSelectLevel = 0;
            } else {
                this.MajorScreenSelectLevel = this.SelectWishWayObj.EducationLevel;
            }
        },
        // 院校筛选条件 显示/隐藏
        CollegeSearchBtn(type){
            if(type==1){
                document.getElementById('query_layer_box').style.display='block';
                document.getElementById('query_layer_box').classList.add("layerbackshow");
                document.getElementById('query_layer_box').classList.remove("layerbackhide");
                let obj = document.getElementById("college_list_box");
                obj.style.display='block';
                obj.classList.remove("layerboxhide");
                obj.classList.add("layerboxshow");
                //绑定筛选信息
                let data = getStore("QueryCollegeStoreData",this.queryCopy);
                if(data){
                    data = JSON.parse(data);
                    // 院校优先
                    this.queryCopy.CollegeCityIdList = data.CollegeCityIdList;
                    this.queryCopy.CollegeCategoryIdList = data.CollegeCategoryIdList;
                    this.queryCopy.CollegeNatureIdList = data.CollegeNatureIdList;
                    this.queryCopy.EducationLevel = data.EducationLevel;
                    this.queryCopy.StrengthTagList = data.StrengthTagList;
                    this.queryCopy.FeatureTagList = data.FeatureTagList;
                    this.queryCopy.IsNewCollege = data.IsNewCollege;
                    this.ProvinceCheckBind();// 绑定已选省份
                    // this.CityCheckNumber();// 绑定已选城市数量
                    // this.CityCheckBind();// 绑定已选城市
                    this.queryCopy.CollegeIdList = data.CollegeIdList;
                }
            }
            else{
                document.getElementById('query_layer_box').classList.add("layerbackhide");
                document.getElementById('query_layer_box').classList.remove("layerbackshow");
                let obj = document.getElementById("college_list_box");
                obj.classList.remove("layerboxshow");
                obj.classList.add("layerboxhide");
                let st = setTimeout(()=>{
                    document.getElementById('query_layer_box').style.display='none';
                    obj.style.display='none';
                    clearTimeout(st);
                },300);
            }
        },
        // 筛选数据关闭、回滚
        CloseSearchLayerOfBack(){
            // 院校优先
            this.queryCopy.CollegeCityIdList = this.query.CollegeCityIdList;
            this.queryCopy.CollegeCategoryIdList = this.query.CollegeCategoryIdList;
            this.queryCopy.CollegeNatureIdList = this.query.CollegeNatureIdList;
            this.queryCopy.EducationLevel = this.query.EducationLevel;
            this.queryCopy.StrengthTagList = this.query.StrengthTagList;
            this.queryCopy.FeatureTagList = this.query.FeatureTagList;
            this.queryCopy.IsNewCollege = this.query.IsNewCollege;
            this.queryCopy.CollegeIdList = this.query.CollegeIdList;
            this.CollegeSearchBtn(2);//关闭筛选数据dom
        },
        clearCollegeSearch() {
            this.SearchCollegeList = []
            setTimeout(() => {
                this.ShowCollegeSearch = true
            },100)
        },
        // 删除院校操作
        deleteSSListAll() {
            this.BottomCollegeCardList = []
            this.SelectSearchList = []
            this.query.CollegeIdList = []
            this.ResetOrClearSearchStoreData();// 缓存、重置 筛选信息
        },
        deleteSSList(it, index) {
            this.BottomCollegeCardList = this.BottomCollegeCardList.filter(t => t.Id !== it.Id)
            this.SelectSearchList = deepClone(this.BottomCollegeCardList)
            this.query.CollegeIdList = this.SelectSearchList.map(t => t.Id)
            this.ResetOrClearSearchStoreData();// 缓存、重置 筛选信息
        },
        // 确认勾选
        SureScreen() {
            this.BottomCollegeCardList = deepClone(this.SelectSearchList)
            this.query.CollegeIdList = this.SelectSearchList.map(i => i.Id)
            this.ResetOrClearSearchStoreData();// 缓存、重置 筛选信息
            this.ShowCollegeSearch = false
        },
        SelectSearchItem(item) {
            if (!item.IsPlan) return

            let tempIndex = this.SelectSearchList.findIndex(i => i.Id === item.Id)
            if (tempIndex !== -1) {
                this.SelectSearchList.splice(tempIndex, 1)
            } else {
                this.SelectSearchList.push(item)
            }
        },
        // 筛选 搜索院校
        searchCollege() {
            if (!this.CollegeSeachForm.CollegeName){
                this.$message.info('请输入院校');
                return;
            }
            this.Showloading();
            API.Query.SearchCollegeByName({Name:this.CollegeSeachForm.CollegeName}).then(res => {
                if (res.Code==0) {
                    this.SearchCollegeList = res.Data;
                    this.ShowCollegeSearch = true
                    this.loading.close()
                }
                else {
                    this.$message.error(res.Message)
                     this.loading.close()
                }
            })
        },
        // 省份选择
        ProvinceCheck(item){
            if(item!=''){
                let flag = this.queryCopy.CollegeCityIdList.includes(item.ProvinceId)
                if(flag){
                    this.queryCopy.CollegeCityIdList = this.queryCopy.CollegeCityIdList.filter(f=>f!==item.ProvinceId);
                    this.CollegeCityCheckListCopy = this.CollegeCityCheckListCopy.filter(f=>f.ProvinceId!=item.ProvinceId);// 移除dom选中的当前省份
                }
                else{
                    this.queryCopy.CollegeCityIdList.push(item.ProvinceId);
                    this.CollegeCityCheckListCopy.push({ProvinceId: item.ProvinceId,ProvinceName: item.ProvinceName});// 添加dom选中的当前省份
                }
            }
            else{
                this.queryCopy.CollegeCityIdList = [];
                this.CollegeCityCheckListCopy = [];
            }
        },
        // 省份移除
        ProvinceRemove(id){
            if(id!=''){
                this.queryCopy.CollegeCityIdList = this.queryCopy.CollegeCityIdList.filter(f=>f!==id);
                this.CollegeCityCheckListCopy = this.CollegeCityCheckListCopy.filter(f=>f.ProvinceId!=id);// 移除dom选中的当前省份
            }
            else{
                this.queryCopy.CollegeCityIdList = [];
                this.CollegeCityCheckListCopy = [];
            }
            this.SeachFormSubmit();//刷新数据
        },
        // 绑定已选省份
        ProvinceCheckBind(){
            this.CollegeCityCheckListCopy = [];
            this.CollegeScreen.CollegeAreaList.forEach(item=>{
                if(this.queryCopy.CollegeCityIdList.indexOf(item.ProvinceId)>-1){
                    this.CollegeCityCheckListCopy.push({ProvinceId: item.ProvinceId,ProvinceName: item.ProvinceName});
                }
            });
        },
        // 城市选择
        ProvinceCityCheck(item){
            let temp = this.queryCopy.CollegeCityIdList.find(f => f === item.CityId);// 判断是否存在当前城市
            if(temp){
                this.queryCopy.CollegeCityIdList = this.queryCopy.CollegeCityIdList.filter(f=>f!=item.CityId);// 移除查询条件的当前城市
                this.CollegeCityCheckListCopy = this.CollegeCityCheckListCopy.filter(f=>f.CityId!=item.CityId);// 移除dom选中的当前城市
            }
            else{
                this.queryCopy.CollegeCityIdList.push(item.CityId);// 添加查询条件的当前城市
                this.CollegeCityCheckListCopy.push({CityId: item.CityId,CityName: item.CityName});// 添加dom选中的当前城市
            }
            this.CityCheckNumber();//更新已选城市数量
        },
        // 城市选择/全选
        ProvinceCityAllCheck(item){
            item.CityList.forEach(obj=>{
                this.queryCopy.CollegeCityIdList = this.queryCopy.CollegeCityIdList.filter(f=>f!=obj.CityId);// 移除查询条件的当前城市
                this.CollegeCityCheckListCopy = this.CollegeCityCheckListCopy.filter(f=>f.CityId!=obj.CityId);// 移除dom选中的当前城市
            });
            if(item.CheckNumber!=item.CityList.length){
                item.CityList.forEach(obj=>{
                    this.queryCopy.CollegeCityIdList.push(obj.CityId);// 添加查询条件的当前城市
                    this.CollegeCityCheckListCopy.push({CityId: obj.CityId,CityName: obj.CityName});// 添加dom选中的当前城市
                });
            }
            this.CityCheckNumber();//更新已选城市数量
        },
        // 城市移除
        ProvinceCityRemove(item){
            if(item!=''){
                this.queryCopy.CollegeCityIdList = this.queryCopy.CollegeCityIdList.filter(f=>f!=item.CityId);// 移除查询条件的当前城市
                this.CollegeCityCheckListCopy = this.CollegeCityCheckListCopy.filter(f=>f.CityId!=item.CityId);// 移除dom选中的当前城市
            }
            else{
                this.queryCopy.CollegeCityIdList = [];
                this.CollegeCityCheckListCopy = [];
            }
            this.SeachFormSubmit();//刷新数据
        },
        // 绑定已选城市数量
        CityCheckNumber(){
            this.CollegeScreen.CollegeAreaList.forEach(item=>{
                let num=0;
                item.CityList.forEach(c=>{
                    if(this.queryCopy.CollegeCityIdList.indexOf(c.CityId)>-1){
                        num+=1;
                    }
                });
                item.CheckNumber = num;
            });
        },
        // 绑定已选城市
        CityCheckBind(){
            this.CollegeCityCheckListCopy = [];
            this.CollegeScreen.CollegeAreaList.forEach(item=>{
                item.CityList.forEach(c=>{
                    if(this.queryCopy.CollegeCityIdList.indexOf(c.CityId)>-1){
                        this.CollegeCityCheckListCopy.push({CityId: c.CityId,CityName: c.CityName});
                    }
                });
            });
        },
        // 院校类别选择
        CollegeCategoryCheck(item){
            if(item!=''){
                let flag = this.queryCopy.CollegeCategoryIdList.includes(item.Id)
                if(flag){
                    this.queryCopy.CollegeCategoryIdList = this.queryCopy.CollegeCategoryIdList.filter(f=>f!==item.Id);
                }
                else{
                    this.queryCopy.CollegeCategoryIdList.push(item.Id);
                }
            }
            else{
                this.queryCopy.CollegeCategoryIdList = [];
            }
        },
        // 院校类别移除
        CollegeCategoryRemove(id){
            if(id!=''){
                this.queryCopy.CollegeCategoryIdList = this.queryCopy.CollegeCategoryIdList.filter(f=>f!==id);
            }
            else{
                this.queryCopy.CollegeCategoryIdList = [];
            }
            this.SeachFormSubmit();//刷新数据
        },
        // 办学性质选择
        CollegeNatureCheck(item){
            if(item!=''){
                let flag = this.queryCopy.CollegeNatureIdList.includes(item.Id)
                if(flag){
                    this.queryCopy.CollegeNatureIdList = this.queryCopy.CollegeNatureIdList.filter(f=>f!==item.Id);
                }
                else{
                    this.queryCopy.CollegeNatureIdList.push(item.Id);
                }
            }
            else{
                this.queryCopy.CollegeNatureIdList = [];
            }
        },
        // 办学性质移除
        CollegeNatureRemove(id){
            if(id!=''){
                this.queryCopy.CollegeNatureIdList = this.queryCopy.CollegeNatureIdList.filter(f=>f!==id);
            }
            else{
                this.queryCopy.CollegeNatureIdList = [];
            }
            this.SeachFormSubmit();//刷新数据
        },
        // 学历层次选择
        CollegeLevelCheck(value) {
            if (this.queryCopy.EducationLevel === value) {
                this.queryCopy.EducationLevel = null;
            }
            else {
                this.queryCopy.EducationLevel = value;
            }
        },
        // 学历层次移除
        CollegeLevelRemove() {
            this.queryCopy.EducationLevel = null;
            this.SeachFormSubmit();//刷新数据
        },
        // 院校实力标签选择
        CollegeStrengthTagCheck(item){
            if(item!=''){
                let flag = this.queryCopy.StrengthTagList.includes(item.Id)
                if(flag){
                    this.queryCopy.StrengthTagList = this.queryCopy.StrengthTagList.filter(f=>f!==item.Id);
                }
                else{
                    this.queryCopy.StrengthTagList.push(item.Id);
                }
            }
            else{
                this.queryCopy.StrengthTagList = [];
            }
        },
        // 院校实力标签移除
        CollegeStrengthTagRemove(id){
            if(id!=''){
                this.queryCopy.StrengthTagList = this.queryCopy.StrengthTagList.filter(f=>f!==id);
            }
            else{
                this.queryCopy.StrengthTagList = [];
            }
            this.SeachFormSubmit();//刷新数据
        },
        // 院校特色标签选择
        CollegeFeatureTagCheck(item){
            if(item!=''){
                let flag = this.queryCopy.FeatureTagList.includes(item.Id)
                if(flag){
                    this.queryCopy.FeatureTagList = this.queryCopy.FeatureTagList.filter(f=>f!==item.Id);
                }
                else{
                    this.queryCopy.FeatureTagList.push(item.Id);
                }
            }
            else{
                this.queryCopy.FeatureTagList = [];
            }
        },
        // 院校特色标签移除
        CollegeFeatureTagRemove(id){
            if(id!=''){
                this.queryCopy.FeatureTagList = this.queryCopy.FeatureTagList.filter(f=>f!==id);
            }
            else{
                this.queryCopy.FeatureTagList = [];
            }
            this.SeachFormSubmit();//刷新数据
        },
        // 新增院校选择
        IsNewCollegeCheck(){
            if(this.queryCopy.IsNewCollege){
                this.queryCopy.IsNewCollege = null;
            }
            else{
                this.queryCopy.IsNewCollege = true;
            }
        },
        // 新增院校移除
        IsNewCollegeRemove(){
            this.queryCopy.IsNewCollege = null;
            this.ResetOrClearSearchStoreData();// 缓存、重置 筛选信息
        },
        // 筛选提交
        SeachFormSubmit(){
            this.ResetOrClearSearchStoreData();// 缓存、重置 筛选信息
            this.CollegeSearchBtn(2);//关闭院校筛选条件
            this.CollegeData=[];
            this.pageIndex=1;
            this.GetCollegeList();//获取院校列表
        },
        // 缓存、重置 筛选信息
        ResetOrClearSearchStoreData(){
            setStore("QueryCollegeStoreData",this.queryCopy);
            // 院校优先
            this.query.CollegeCityIdList = this.queryCopy.CollegeCityIdList;
            this.query.CollegeCategoryIdList = this.queryCopy.CollegeCategoryIdList;
            this.query.CollegeNatureIdList = this.queryCopy.CollegeNatureIdList;
            this.query.EducationLevel = this.queryCopy.EducationLevel;
            this.query.StrengthTagList = this.queryCopy.StrengthTagList;
            this.query.FeatureTagList = this.queryCopy.FeatureTagList;
            this.query.IsNewCollege = this.queryCopy.IsNewCollege;
            this.CollegeCityCheckList = this.CollegeCityCheckListCopy;// 操作dom
        },
        // 显示选中标签名称
        showName(id, type) {
            let name = ''
            switch (type) {
                case 2:
                this.CollegeScreen.CollegeCategoryList.some(it => {
                    if (it.Id === id) {
                        name = it.Name
                    }
                })
                break;
                case 3:
                this.CollegeScreen.CollegeNatureList.some(it => {
                    if (it.Id === id) {
                        name = it.Name
                    }
                })
                break;
                case 4:
                this.CollegeScreen.EnrollCategoryList.some(it => {
                    if (it.Id === id) {
                        name = it.Name
                    }
                })
                break;
                case 5:
                this.CollegeScreen.FeatureTagList.some(it => {
                    if (it.Id === id) {
                        name = it.Name
                    }
                })
                break;
                case 6:
                this.CollegeScreen.StrengthTagList.some(it => {
                    if (it.Id === id) {
                        name = it.Name
                    }
                })
                break;
            }

            return name
        },
        // 加载
        Showloading() {
            this.loading = this.$loading({
                lock: false,
                text: '加载中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)',
                color: '#000'
            })
        },
        // 获取院校列表
        GetCollegeList(){
            // 处理筛选参数
            this.searchForm={
                ProvinceIdList:this.query.CollegeCityIdList,
                CategoryIdList:this.query.CollegeCategoryIdList,
                NatureIdList:this.query.CollegeNatureIdList,
                EducationLevel:this.query.EducationLevel,
                StrengthTagIdList:this.query.StrengthTagList,
                FeatureTagIdList:this.query.FeatureTagList,
                CollegeName:''
            };
            API.Query.GetCollegeList(this.pageIndex,this.pageSize,this.searchForm).then(res => {
                if(res.Data.Data.length>0){
                    res.Data.Data.forEach(f => {
                        this.CollegeData.push(f);
                    });
                    this.HasNext=res.Data.HasNext;
                    if(this.HasNext){
                        this.pagetxt='点击加载更多';
                    }
                    else{
                        this.pagetxt='已经到底啦！';
                    }
                }
                else{
                    this.pagetxt='未找到院校信息！';
                }
            });
        },
        // 获取热门院校列表
        GetHotCollegeList(){
            API.Query.GetCollegeList(1,10,{}).then(res => {
                if(res.Data.Data.length>0){
                    this.HotCollegeData=res.Data.Data;
                }
            });
        },
        // 详情
        CollegeDetail(id){
            let u = this.$router.resolve({
                path: '/query/collegedetail?id='+id+'&tab=collegeinfo'
            })
            window.open(u.href, '_blank')
        },
        // 院校标签展开/收起
        CollegeTipBtn(id,type){
            let obj1 = document.getElementById('college_tip_id'+id).style;
            let obj2 = document.getElementById('college_tip_down'+id).style;
            let obj3 = document.getElementById('college_tip_up'+id).style;
            if(type==1){
                obj1.height='auto';obj1.overflow='initial';obj2.display='none';obj3.display='block';
            }
            else{
                obj1.height='24px';obj1.overflow='hidden';obj2.display='block';obj3.display='none';
            }
        },
        // 院校对比展开/收起
        OpenCompareBtn(){
            if(this.CompareFixed==0){
                this.AnimateCompareClass = 'animate_close';
                this.CompareFixed=-240;
            }
            else{
                this.AnimateCompareClass = 'animate_open';
                this.CompareFixed=0;
            }
        },
        // 院校对比添加/移除
        CollegeAddCompare(item,type){
            if(type==1){
                this.CollegeCompareData.push(item);
                if(this.CollegeCompareCheckData.length<5){
                    this.CollegeCompareCheckData.push(item.Id);
                }
            }
            else{
                this.CollegeCompareData = this.CollegeCompareData.filter(f=>f.Id!==item.Id);
                if(this.CollegeCompareCheckData.find(f=>f===item.Id)){
                    this.CollegeCompareCheckData = this.CollegeCompareCheckData.filter(f=>f!==item.Id);
                }
            }
            setStore("CollegeCompareItem",this.CollegeCompareData);
            setStore("CollegeCompareCheckItem",this.CollegeCompareCheckData);
        },
        // 勾选院校对比
        CollegeCheck(item){
            if(this.CollegeCompareCheckData.find(f=>f===item.Id)){
                this.CollegeCompareCheckData = this.CollegeCompareCheckData.filter(f=>f!==item.Id);
            }
            else{
                if(this.CollegeCompareCheckData.length<5){
                    this.CollegeCompareCheckData.push(item.Id);
                }
                else{
                    this.$message({message: '每次最多可选5条记录对比',type: 'warning'});
                }
            }
            setStore("CollegeCompareCheckItem",this.CollegeCompareCheckData);
        },
        // 院校对比下拉改变
        CompareCollegeChange(id){
            this.CompareForm.Id=null;
            let obj = this.CollegeCompareData.find(f=>f.Id==id);
            if(obj){
                this.$message({message: '已添加：'+obj.CollegeName,type: 'warning'});return false;
            }
            let item = this.CollegeSelectData.find(f=>f.Id==id);
            if(id&&item){
                this.CollegeAddCompare(item,1);// 院校对比添加
            }
            this.CollegeSelectData = [];
        },
        // 对比 搜索院校
        RemoteMethodCollege(name) {
            if (name.trim()) {
                this.cloading = true;
                let st = setTimeout(() => {
                    // 获取院校
                    API.Query.SearchCollegeByName({Name:name}).then(res => {
                        this.CollegeSelectData = res.Data;
                        this.CollegeSelectData.forEach(item=>{
                            item.CollegeName=item.Name;
                            if(this.CollegeCompareData.find(f=>f.Id==item.Id)){
                                item.Disabled = true;
                            }
                        });
                        this.cloading = false;
                        clearTimeout(st);
                    });
                }, 200);
            } else {
                this.CollegeSelectData = [];
            }
        },
        // 开始对比
        StartCompareBtn(){
            if(this.CollegeCompareData.length==0){
                this.$message({message: '请添加要对比的院校',type: "warning"});return false;
            }
            if(this.CollegeCompareCheckData.length==0){
                this.$message({message: '请勾选要对比的院校',type: 'warning'});return false;
            }
            if(this.CollegeCompareCheckData.length<2){
                this.$message({message: '至少要勾选2个院校',type: 'warning'});return false;
            }
            this.$router.push("/query/compare");
        },
        //加载更多
        pagesbtn(){
            if(this.HasNext){
                if(this.pagetxt=='点击加载更多'){
                    this.pageIndex+=1;
                    this.GetCollegeList();
                }
            }else{
                if(this.CollegeData.length!=0){
                    this.pagetxt='已经到底啦！';
                }
            }
        },
        // 填报
        ToZntb(){
            this.$router.push("/index");
        },
        // 院校标签格式转换
        GetTagsArr(item){
            if(item){
                if(item.indexOf(',')!=-1){
                    return item.split(',');
                }
                else{
                    return [item];
                }
            }
        },
        toCollegeOrder(){
            this.$router.push("/query/collegerank");
        }
    }
}
</script>
<style scoped lang="less">
.college_box{
    max-width: 1420px;
    margin: 20px auto 50px;
    user-select: none;
    .college_con{
        margin: 0px 20px;
        border: 1px solid #eee;
        box-shadow: 0px 0px 3px 1px #ebeaea;
        .searchbox{
            padding: 15px 20px;
            background-color: #fff;
            border-bottom: 1px solid #ebe9e9;
            box-shadow: 0px 0px 3px 1px #ebeaea;
            .i_box{
                .qbtn{
                    width: 100px;
                    float: left;
                    color: #666;
                    font-size: 15px;
                    line-height: 38px;
                    border: 1px solid #ddd;
                    border-radius:5px;
                    text-align:center;
                    i{
                        margin-left: 2px;
                        color: rgba(var(--themecolor), 0.8);
                    }
                }
                .qbtn:hover{
                    cursor: pointer;
                    color: rgba(var(--themecolor), 1);
                    border: 1px solid rgba(var(--themecolor), 0.5);
                    i{
                        color: rgba(var(--themecolor), 1);
                    }
                }
                .num{
                    float: left;
                    width: 150px;
                    line-height: 40px;
                    color: #666;
                    font-size: 12px;
                    text-align: center;
                    padding-right: 10px;
                    .n{
                        font-size: 14px;
                        color: rgba(var(--themecolor),0.75);
                    }
                }
                .stxt{
                    position: relative;
                    float: left;
                    width: calc(100% - 475px);
                    height: 40px;
                    input{
                        display: block;
                        width: 100%;
                        height: 100%;
                        line-height: 38px;
                        border: none;
                        line-height: 1;
                        text-indent: 20px;
                        color: #666;
                        font-size: 14px;
                        background-color: #fff;
                    }
                    i{
                        position: absolute;
                        right: 0px;
                        top: 0px;
                        z-index: 1;
                        width: 38px;
                        height: 100%;
                        text-align: center;
                        line-height: 38px;
                        color: #999;
                        font-size: 16px;
                    }
                    i:hover{
                        cursor: pointer;
                        color: #666;
                    }
                }
                .search-box {
                    position: absolute;
                    top: 100%;
                    left: 0;
                    z-index: 9;
                    width: 100%;
                    min-height: 100px;
                    max-height: 300px;
                    border: 1px solid #f0f0f0;
                    border-radius: 3px;
                    box-shadow: 0 0 10px #c3c3ed;
                    background-color: #fff;
                    font-size: 14px;

                    overflow: auto;

                    &::-webkit-scrollbar {
                        width: 6px;
                        background-color: #f1f1f1;
                        border-radius: 5px;
                    }
                    &::-webkit-scrollbar-thumb {
                        background-color: #e7e7e7;
                        border-radius: 5px;
                    }
                    &::-webkit-scrollbar-thumb:hover {
                        background-color: #d5d4d4;
                    }

                    .search-li {
                        padding: 5px 20px 5px 50px;
                        cursor: pointer;
                        color: #616060;
                        font-size: 15px;
                        border-bottom: 1px solid #f3eeee;
                        position: relative;
                        &:hover {
                            color: rgba(var(--themecolor),1);
                        }
                        .selected-icon {
                            position: absolute;
                            left: 20px;
                            color: rgba(var(--themecolor),1);
                        }
                    }
                    .no-data {
                        padding-top: 40px;
                        text-align: center;
                        color: #999;
                    }
                    .DisableSelect {
                        color: #999;
                        &:hover {
                            cursor: not-allowed;
                            color: #999;
                        }
                    }

                    .search-sure {
                        padding: 6px 20px;
                        margin: 0 10px;
                        border: 1px solid rgba(var(--themecolor), 1);
                        border-radius: 5px;
                        text-align: center;
                        background-color: rgba(var(--themecolor), 1);
                        color: #fff;
                        font-size: 15px;
                        cursor: pointer;

                        position: sticky;
                        bottom: 0;
                        left: 0;
                        z-index: 3;
                        &:hover {
                            .text {
                                color: #efe9e9;
                            }
                            .icon {
                                color: #efe9e9;
                            }
                        }

                        .icon {
                            position: inherit;
                            color: #fff;
                        }
                    }
                }
                .s_btn{
                    width: 200px;
                    height: 40px;
                    line-height: 40px;
                    text-align: center;
                    color: #fff;
                    font-size: 15px;
                    border-radius: 3px;
                    background-color: rgba(var(--themecolor),0.75);
                    float: right;
                    transition: all 0.2s;
                    -webkit-transition: all 0.2s;
                    i{
                        margin-right: 10px;
                    }
                }
                .s_btn:hover{
                    cursor: pointer;
                    background-color: rgba(var(--themecolor),1);
                }
            }
            .t_box{
                margin-top: 15px;
                .ttxt{
                    width: 90px;
                    float: left;
                    color: #666;
                    font-size: 15px;
                    line-height: 38px;
                }
                .t_data{
                    width: calc(100% - 90px);
                    float: right;
                    .data{
                        float: left;
                        border: 1px solid rgba(var(--themecolor),0.75);
                        border-radius: 3px;
                        position: relative;
                        overflow: hidden;
                        margin: 0px 15px 10px 0px;
                        padding: 4px 45px 4px 10px;
                        .tit{
                            color: #666;
                            font-size: 14px;
                        }
                        .d{
                            display: inline-block;
                            color: #666;
                            font-size: 12px;
                            border: 1px solid #ddd;
                            border-radius: 20px;
                            background-color: #fff;
                            height: 20px;
                            line-height: 20px;
                            padding: 0px 5px 0px 12px;
                            margin: 4px 4px;
                            i{
                                margin-left: 6px;
                                color: #999;
                                font-size: 14px;
                            }
                            i:hover{
                                color: rgba(var(--themecolor),1);
                                cursor: pointer;
                            }
                        }
                        .close{
                            width: 40px;
                            text-align: center;
                            height: 100%;
                            position: absolute;
                            right: 0;
                            top:0;
                            background-color: rgba(var(--themecolor),0.75);
                            i{
                                display: block;
                                color: #f0ecec;
                                font-size:20px;
                                height: 20px;
                                line-height: 20px;
                                width: 100%;
                                position: relative;
                                top: calc(50% - 10px);
                                transition: all 0.2s;
                                -webkit-transition: all 0.2s;
                            }
                        }
                        .close:hover{
                            cursor: pointer;
                        }
                        .close:hover i{
                            transform: rotate(180deg);
                            -webkit-transform: rotate(180deg);
                        }
                    }
                }
            }
        }
        .query_layer_box{
            position: fixed;
            left: 0;
            top: 0;
            z-index: 19;
            width: 100%;
            height: 100%;
            display: none;
            opacity: 0;
            background-color: rgba(0,0,0,0.5);
            .m_box{
                margin: 50px auto 0;
                max-width: 1420px;
                height: calc(100% - 100px);
                position: relative;
                .m_list{
                    border: 1px solid #ddd;
                    margin: 0 20px;
                    background-color: #ffffff;
                    box-shadow: 0px 3px 5px 0px #d4d2d2;
                    border-radius: 5px;
                    z-index: 2;
                    opacity: 0;
                    display: none;
                    height: 100%;
                    .sx_scrollbar{
                        width: 100%;
                        height: 100%;
                    }
                    .s_box{
                        margin: 0 20px 15px 20px;
                        border-bottom: 1px dashed #c4c2c2;
                        padding-bottom: 5px;
                        .tit{
                            color: #666;
                            font-size: 14px;
                            float: left;
                            width: 80px;
                            text-align: right;
                        }
                        .box{
                            width: calc(100% - 100px);
                            float: right;
                            .item{
                                display: inline-block;
                                color: #666;
                                font-size: 12px;
                                padding: 2px 18px;
                                border: 1px solid #e2e2e2;
                                border-radius: 20px;
                                margin: 0px 8px 10px;
                                cursor: pointer;
                                transition: all 0.2s;
                                -webkit-transition: all 0.2s;
                            }
                            .item:hover{
                                border: 1px solid rgba(var(--themecolor),0.75);
                                color: rgba(var(--themecolor),0.75);
                            }
                            .item.on{
                                border: 1px solid rgba(var(--themecolor),0.75);
                                background-color: rgba(var(--themecolor),0.75);
                                color: #fff;
                            }
                        }
                    }
                    .s_box:nth-child(1){
                        margin-top: 20px;
                    }
                    .level {
                        .level-title {
                            padding-left: 60px;
                            color: #666;
                            font-size: 14px;
                            width: 80px;
                            text-align: right;
                        }
                        .level-box {
                            .level-item {
                                color: #666;
                                font-size: 12px;
                                padding: 2px 18px;
                                border: 1px solid #e2e2e2;
                                border-radius: 20px;
                                margin: 0px 8px 10px;
                                transition: all 0.2s;
                                -webkit-transition: all 0.2s;
                                position: relative;
                                &:hover {
                                    border: 1px solid rgba(var(--themecolor),0.75);
                                    cursor: pointer;
                                    color: rgba(var(--themecolor),0.75);
                                }
                            }
                            .level-item.on {
                                border: 1px solid rgba(var(--themecolor),0.75);
                                background-color: rgba(var(--themecolor),0.75);
                                color: #fff;
                            }
                        }
                    }
                    .btnbox{
                        width: 100%;
                        height: 60px;
                        background-color: #fff;
                        border-top: 1px solid #ddd;
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        z-index: 2;
                        text-align: center;
                        .s_back{
                            width: 200px;
                            height: 40px;
                            line-height: 40px;
                            border-radius: 3px;
                            background-color: #e0e0e0;
                            color: #666;
                            font-size: 15px;
                            letter-spacing: 2px;
                            text-align: center;
                            margin: 10px 20px;
                            display: inline-block;
                        }
                        .s_back:hover{
                            cursor: pointer;
                            background-color: #d6d6d6;
                        }
                        .s_submit{
                            width: 200px;
                            height: 40px;
                            line-height: 40px;
                            border-radius: 3px;
                            background-color: rgba(var(--themecolor),0.75);
                            color: #fff;
                            font-size: 15px;
                            letter-spacing: 2px;
                            text-align: center;
                            margin: 10px 20px;
                            display: inline-block;
                        }
                        .s_submit:hover{
                            cursor: pointer;
                            background-color: rgba(var(--themecolor),1);
                        }
                    }
                }
                .m_list.layerboxshow {
                    animation: layerboxshow 0.3s ease 0s 1;
                    -webkit-animation: layerboxshow 0.3s ease 0s 1;
                    animation-fill-mode: forwards;
                    -webkit-animation-fill-mode: forwards;
                    @-webkit-keyframes layerboxshow {
                        from {
                            top: -100px;
                            opacity: 0;
                        }
                        to {
                            top: 39px;
                            opacity: 1;
                        }
                    }
                    @keyframes layerboxshow {
                        from {
                            top: -100px;
                            opacity: 0;
                        }
                        to {
                            top: 39px;
                            opacity: 1;
                        }
                    }
                }
                .m_list.layerboxhide {
                    animation: layerboxhide 0.3s ease 0s 1;
                    -webkit-animation: layerboxhide 0.3s ease 0s 1;
                    animation-fill-mode: forwards;
                    -webkit-animation-fill-mode: forwards;
                    @-webkit-keyframes layerboxhide {
                        from {
                            top: 39px;
                            opacity: 1;
                        }
                        to {
                            top: -100px;
                            opacity: 0;
                        }
                    }
                    @keyframes layerboxhide {
                        from {
                            top: 39px;
                            opacity: 1;
                        }
                        to {
                            top: -100px;
                            opacity: 0;
                        }
                    }
                }
            }
        }
        .query_layer_box.layerbackshow {
            animation: layerbackshow 0.3s ease 0s 1;
            -webkit-animation: layerbackshow 0.3s ease 0s 1;
            animation-fill-mode: forwards;
            -webkit-animation-fill-mode: forwards;
            @-webkit-keyframes layerbackshow {
                from {
                    opacity: 0;
                }
                to {
                    opacity: 1;
                }
            }
            @keyframes layerbackshow {
                from {
                    opacity: 0;
                }
                to {
                    opacity: 1;
                }
            }
        }
        .query_layer_box.layerbackhide {
            animation: layerbackhide 0.3s ease 0s 1;
            -webkit-animation: layerbackhide 0.3s ease 0s 1;
            animation-fill-mode: forwards;
            -webkit-animation-fill-mode: forwards;
            @-webkit-keyframes layerbackhide {
                from {
                    opacity: 1;
                }
                to {
                    opacity: 0;
                }
            }
            @keyframes layerbackhide {
                from {
                    opacity: 1;
                }
                to {
                    opacity: 0;
                }
            }
        }
        .college_list{
            width: 100%;
            padding: 10px 0;
            min-height: 300px;
            .college_table{
                width: calc(100% - 320px);
                float:left;
            }
            .ad_con{
                width: 310px;
                float:right;
                margin-right:10px;
                margin-top:10px;
                .zntb_box{
                    user-select: none;
                    width: 100%;
                    height: 132px;
                    border-radius:5px;
                    overflow: hidden;
                    cursor: pointer;
                    position: relative;
                    .img{
                        width:100%;
                        height: 132px;
                        position: absolute;
                        left: 0;
                        top: 0;
                        z-index: 1;
                        background:linear-gradient(to right, rgba(var(--themecolor),0.6), rgba(var(--themecolor),0.8));
                        transition: all 0.25s;
                        -webkit-transition: all 0.25s;
                        img{
                            width: 100%;
                            display: block;
                        }
                    }
                }
                .zntb_box:hover .img{
                    transform: scale(1.08);
                    -webkit-transform: scale(1.08);
                }
                .rmyx_box{
                    border: 1px solid #ebebeb;
                    margin-top: 20px;
                    background-color: #fff;
                    overflow: hidden;
                    border-radius: 5px;
                    padding-bottom: 20px;
                    .rtit{
                        border-bottom: 1px solid #ebebeb;
                        height: 40px;
                        line-height: 40px;
                        i{
                            float: left;
                            margin: 11px 15px 0px 20px;
                            width: 6px;
                            height: 19px;
                            background-color: rgb(var(--themecolor));
                        }
                        span{
                            font-size: 16px;
                            color: #555;
                            font-weight: bold;
                        }
                    }
                    .rlist{
                        padding-top: 10px;
                        .ra{
                            margin-left: 14px;
                            height: 44px;
                            line-height: 44px;
                            .ico{
                                float: left;
                                width: 30px;
                                height: 100%;
                                position: relative;
                                margin-right: 12px;
                                i{
                                    font-size: 30px;
                                    color: rgb(var(--themecolor));
                                }
                                span{
                                    font-size: 14px;
                                    position: absolute;
                                    top: 0px;
                                    left: 11px;
                                    color: #666;
                                }
                            }
                            .txt{
                                color: #555;
                                font-size: 16px;
                                float: left;
                                width: calc(100% - 56px);
                                transition: color 0.3s;
                                -webkit-transition: color 0.3s;
                            }
                        }
                        .ra:hover{
                            cursor: pointer;
                        }
                        .ra:hover .txt{
                            color: rgb(var(--themecolor));
                        }
                        .ra:nth-child(-n+3) .ico span{
                            color: #fff;
                        }
                        .ra:nth-child(1) .ico i{
                            color: #eb312d;
                        }
                        .ra:nth-child(2) .ico i{
                            color: #fe7002;
                        }
                        .ra:nth-child(3) .ico i{
                            color: #f7aa4c;
                        }
                    }
                }
                .college_order{
                    margin-top: 20px;
                    background-color: #fff;
                    overflow: hidden;
                    border-radius: 5px;
                    height: 70px;
                    line-height: 70px;
                    padding-left: 40px;
                    background: linear-gradient(to right, rgba(var(--themecolor),0.6), rgba(var(--themecolor),0.8));
                    i{
                        font-size: 46px;
                        display: inline-block;
                        color: #f1e6b1;
                    }
                    .name{
                        color: #f1f0f0;
                        font-size: 24px;
                        letter-spacing: 2px;
                        display: inline-block;
                        margin-left: 30px;
                    }
                }
                .college_order:hover{
                    cursor: pointer;
                    background: linear-gradient(to right, rgba(var(--themecolor),0.7), rgba(var(--themecolor),0.9));
                }
            }
            .college_item{
                margin: 10px;
                border: 1px solid #e7e5e5;
                box-shadow: 0px 0px 3px 1px #ebeaea;
                border-radius: 5px;
                overflow: hidden;
                background-color: #fff;
                .logo{
                    width: 80px;
                    height: 80px;
                    overflow: hidden;
                    margin: 20px;
                    float: left;
                    cursor: pointer;
                }
                .logo:hover{
                    opacity: 0.8;
                }
                .mark{
                    float: left;
                    margin-top: 15px;
                    width: calc(100% - 300px);
                    .name{
                        color: #666;
                        font-size: 18px;
                        margin-right: 20px;
                        font-weight: bold;
                        letter-spacing: 1px;
                        cursor: pointer;
                    }
                    .name:hover{
                        color: rgba(var(--themecolor),0.75);
                    }
                    .con{
                        display: inline-block;
                    }
                    .txt{
                        color: #666;
                        font-size: 14px;
                        margin-right: 20px;
                        i{
                            margin-right: 5px;
                            color: #a39f9f;
                            font-size: 13px;
                        }
                    }
                    .dress{
                        width: 100%;
                        line-height: 20px;
                        margin-top: 10px;
                        color: #777;
                        font-size: 13px;
                        span{
                            display: inline-block;
                            margin-right: 20px;
                            i{
                                margin-right: 4px;
                            }
                        }
                    }
                    .tips{
                        margin-top: 10px;
                        height: 24px;
                        overflow: hidden;
                        position: relative;
                        padding-right: 60px;
                        margin-bottom: 10px;
                        span{
                            display: inline-block;
                            padding: 1px 10px;
                            background-color: rgba(var(--themecolor),0.1);
                            color: #777575;
                            font-size: 12px;
                            margin: 0px 6px 5px 0px;
                            border-radius: 20px;
                        }
                        .more{
                            position: absolute;
                            right: 0;
                            top: 0;
                            width: 60px;
                            height: 24px;
                            line-height: 24px;
                            text-align: center;
                            background-color: #fff;
                            color: #888;
                            font-size: 12px;
                        }
                        .more:hover{
                            cursor: pointer;
                            color: rgba(var(--themecolor),0.75);
                        }
                    }
                }
                .btnbox{
                    width: 160px;
                    float: right;
                    height: 100%;
                    .btn{
                        width: 120px;
                        margin: auto;
                        margin-top: 43px;
                        background-color: #faf3f3;
                        border: 1px solid rgba(var(--themecolor),0.4);
                        color: #666;
                        font-size: 14px;
                        letter-spacing: 1px;
                        text-align: center;
                        height: 32px;
                        line-height: 32px;
                        border-radius:3px;
                        i{
                            font-size: 16px;
                            margin-left: 5px;
                        }
                    }
                    .btn:hover{
                        cursor: pointer;
                        color: rgba(var(--themecolor),1);
                    }
                    .btn.remove{
                        color: #fff;
                        background-color: rgba(var(--themecolor),0.75);
                        border: 1px solid rgba(var(--themecolor),0.75);
                    }
                    .btn.remove:hover{
                        background-color: rgba(var(--themecolor),1);
                        border: 1px solid rgba(var(--themecolor),1);
                    }
                }
            }
            .pages{
                width: 100%;
                min-height: 50px;
                line-height: 50px;
                color: #666;
                font-size: 16px;
                text-align: center;
                letter-spacing: 1px;
                margin-bottom: 10px;
                user-select: none;
                .iconfont{
                    font-size: 50px;
                    display: block;
                    margin-top: 40px;
                    color: #9b9b9b;
                }
            }
            .pages:hover{
                color: rgb(var(--themecolor));
                cursor: pointer;
            }
        }
        .compare_box{
            position: fixed;
            width: 240px;
            height: 476px;
            top: 181px;
            z-index: 21;
            .compare{
                position: relative;
                width: 100%;
                height: 100%;
                .open{
                    position: absolute;
                    left: -48px;
                    top: 140px;
                    width: 48px;
                    height: 200px;
                    border-right: none;
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                    background-color: rgba(var(--themecolor),1);
                    cursor: pointer;
                    .ico{
                        width: 18px;
                        height: 100%;
                        line-height: 200px;
                        text-align: center;
                        color: #e9e8e8;
                        font-size: 13px;
                        float: left;
                        transition: all 0.3s;
                        -webkit-transition: all 0.3s;
                    }
                    .tit{
                        width: 14px;
                        height: 108px;
                        color: #e9e8e8;
                        font-size: 16px;
                        line-height: 22px;
                        margin-left: 2px;
                        margin-top: 30px;
                        float: left;
                        position: relative;
                        .num{
                            position: relative;
                            bottom: -25px;
                            left: -20px;
                            width: 46px;
                            padding: 5px 0px;
                            text-align: center;
                            border-bottom-left-radius: 5px;
                            background-color: #fff;
                            color: rgba(var(--themecolor),1);
                            font-weight: bold;
                            overflow: hidden;
                            border: 1px solid rgba(var(--themecolor),1);
                        }
                    }
                }
                .open:hover .ico{
                    animation: animate_ico 1s ease 0s infinite;
                    -webkit-animation: animate_ico 1s ease 0s infinite;
                    @-webkit-keyframes animate_ico {
                        0% {
                            opacity: 1;
                        }
                        50% {
                            opacity: 0.3;
                        }
                        100% {
                            opacity: 1;
                        }
                    }
                    @keyframes animate_ico {
                        0% {
                            opacity: 1;
                            font-size: 13px;
                        }
                        50% {
                            opacity: 0.3;
                        }
                        100% {
                            opacity: 1;
                        }
                    }
                }
                
                .db{
                    position: absolute;
                    left: -48px;
                    top: 365px;
                    width: 48px;
                    height: 48px;
                    cursor: pointer;
                    background-color: rgba(var(--themecolor),1);
                    margin-top: 5px;
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                    text-align: center;
                    span{
                        letter-spacing: 2px;
                        display: inline-block;
                        width: 28px;
                        margin-top: 7px;
                        font-size: 12px;
                        color: #fff;
                        line-height: 16px;
                    }
                }
                .list{
                    width: 100%;
                    height: 100%;
                    background-color: #fff;
                    border-radius: 5px;
                    border: 1px solid #cecece;
                    .search{
                        width: 100%;
                        height: 36px;
                        position: relative;
                        margin-top: 10px;
                        i{
                            display: inline-block;
                            width: 30px;
                            height: 36px;
                            text-align: center;
                            line-height: 40px;
                            color: #999;
                            font-size: 14px;
                            position: absolute;
                            left: 10px;
                            top: 0px;
                            z-index: 1;
                        }
                        .el-select{
                            display: inline-block;
                            width: 220px;
                            margin-left: 10px;
                        }
                    }
                    .dlist{
                        margin: 10px 10px 0;
                        height: 327px;
                        .comparescroll{
                            height: 100%;
                            width: 222px;
                        }
                        .dl:last-child{
                            border-bottom: none;
                        }
                        .dl{
                            width: 100%;
                            height: 40px;
                            border-bottom: 1px solid #dddada;
                            position: relative;
                            .check{
                                display: block;
                                width: 40px;
                                height: 40px;
                                position: absolute;
                                left: 0px;
                                cursor: pointer;
                                .ch{
                                    width: 16px;
                                    height: 16px;
                                    border: 1px solid #ccc9c9;
                                    border-radius: 3px;
                                    overflow: hidden;
                                    display: block;
                                    margin: 11px 0px 0px 0px;
                                    i{
                                        font-size: 14px;
                                        color: #fff;
                                        display: none;
                                    }
                                }
                            }
                            .check:hover .ch{
                                border: 1px solid rgba(var(--themecolor),0.65);
                            }
                            .sname{
                                float: left;
                                width: 188px;
                                height: 100%;
                                .el-button{
                                    color: #666;
                                    font-size: 14px;
                                    display: block;
                                    width: 100%;
                                    height: 100%;
                                    line-height: 38px;
                                    overflow: hidden;
                                    border-color: transparent;
                                    background-color: transparent;
                                    padding: 0;
                                    overflow:hidden;text-overflow:ellipsis;white-space:nowrap;
                                    text-align: left;
                                    text-indent: 28px;
                                }
                                .el-button:active{
                                    border-color: transparent;
                                }
                                .el-button:focus, .el-button:hover{
                                    background-color: transparent;
                                }
                            }
                            .sname:hover  .el-button{
                                color: rgba(var(--themecolor),0.75);
                            }
                            .delete{
                                color: #999;
                                font-size: 16px;
                                display: block;
                                float: left;
                                width: 32px;
                                height: 100%;
                                line-height: 40px;
                                text-align: center;
                            }
                            .delete:hover{
                                cursor: pointer;
                                color: rgba(var(--themecolor),1);
                            }
                        }
                        .dl.on .check .ch{
                            background-color: rgba(var(--themecolor),0.75);
                            border: 1px solid rgba(var(--themecolor),0.75);
                        }
                        .dl.on .check .ch:hover{
                            cursor: pointer;
                            background-color: rgba(var(--themecolor),1);
                            border: 1px solid rgba(var(--themecolor),1);
                        }
                        .dl.on .check .ch i{
                            display: block;
                            line-height: 18px;
                        }
                        .mark{
                            text-align: center;
                            line-height: 40px;
                            color: #666;
                            font-size: 12px;
                        }
                        .btn{
                            width: 160px;
                            height: 38px;
                            text-align: center;
                            line-height: 38px;
                            color: #fff;
                            font-size: 14px;
                            background-color: rgba(var(--themecolor),0.85);
                            cursor: pointer;
                            letter-spacing: 1px;
                            margin: auto;
                            border-radius: 5px;
                            transition: all 0.2s;
                            -webkit-transition: all 0.2s;
                        }
                        .btn:hover{
                            background-color: rgba(var(--themecolor),1);
                        }
                    }
                }
            }
        }
        .compare_box.animate_open{
            animation: animate_open 0.3s ease 0s 1;
            -webkit-animation: animate_open 0.3s ease 0s 1;
            animation-fill-mode: forwards;
            -webkit-animation-fill-mode: forwards;
            @-webkit-keyframes animate_open {
                from {
                    right: -240px;
                }
                to {
                    right: 0px;
                }
            }
            @keyframes animate_open {
                from {
                    right: -240px;
                }
                to {
                    right: 0px;
                }
            }
        }
        .compare_box.animate_close{
            animation: animate_close 0.3s ease 0s 1;
            -webkit-animation: animate_close 0.3s ease 0s 1;
            animation-fill-mode: forwards;
            -webkit-animation-fill-mode: forwards;
            @-webkit-keyframes animate_close {
                from {
                    right: 0px;
                }
                to {
                    right: -240px;
                }
            }
            @keyframes animate_close {
                from {
                    right: 0px;
                }
                to {
                    right: -240px;
                }
            }
        }
    }
}
.el-select-dropdown__item.selected{
    color: rgba(var(--themecolor),1);
}
.search_citybox{
    overflow: hidden;
    z-index: 2;
    padding: 10px 0 10px;
    .citem{
        display: inline-block;
        color: #666;
        font-size: 12px;
        padding: 2px 18px;
        border: 1px solid #e2e2e2;
        border-radius: 20px;
        margin: 0px 8px 10px;
        transition: all 0.2s;
        -webkit-transition: all 0.2s;
    }
    .citem:hover{
        border: 1px solid rgba(var(--themecolor),0.75);
        cursor: pointer;
        color: rgba(var(--themecolor),0.75);
    }
    .citem.on{
        border: 1px solid rgba(var(--themecolor),0.75);
        background-color: rgba(var(--themecolor),0.75);
        color: #fff;
    }
}
</style>
<style lang="less">
.college_box .college_con .searchbox .el-input.is-active .el-input__inner, .college_box .college_con .searchbox .el-input .el-input__inner:focus{
    border: 1px solid rgba(var(--themecolor),0.75);
}
.college_box .college_con .compare_box .el-input .el-input__inner{
    text-indent: 28px;
    color: #666;
    height: 36px;
    padding: 0;
}
.college_box .college_con .compare_box .el-input__icon{
    line-height: normal;
}
.college_box .college_con .compare_box .el-select .el-input__inner:focus{
    border-color:rgba(var(--themecolor),0.75);
}
.college_box .college_con .compare_box .el-range-editor.is-active, .college_box .college_con .compare_box .el-range-editor.is-active:hover, .college_box .college_con .compare_box .el-select .el-input.is-focus .el-input__inner{
    border-color:rgba(var(--themecolor),0.75);
}
.college_box .college_con .compare_box .el-scrollbar__wrap{
    overflow-x: hidden;
}
.query_layer_box  .el-scrollbar__wrap{
    overflow-x: hidden;
}
.search_city_popover.el-popover{
    border: 1px solid #e0e0e0;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,.3) !important;
}
.query_layer_box .m_box .m_list .s_box .box .el-button{
    padding:3px 10px 3px 18px;
    margin: 0px 8px 10px;
    border-radius: 20px;
    color: #666;
    font-size: 12px;
    position: relative;
    i{
        color: #999;
        font-size: 12px;
        margin-left: 3px;
    }
    .num{
        position: absolute;
        right: -7px;
        top: -8px;
        z-index: 2;
        font-size: 8.5px;
        background-color: rgba(var(--themecolor), 0.75);
        color: #fff;
        width: 12px;
        height: 12px;
        border-radius: 12px;
        line-height: 12px;
        text-align: center;
        overflow: hidden;
    }
}
</style>